<template>
  <div>
    <section class="m-1">
      <b-row>
        <b-col cols="12" md="4"
          ><b-form-input
            v-model="searchValue"
            @input="onSearch"
            placeholder="search..."
          ></b-form-input>
        </b-col>
        <b-col cols="6" md="6">
          <b-form-radio-group
            @input="onSearch"
            id="radio-group-1"
            v-model="selectedEventType"
            :options="optionsEventTypes"
            name="radio-options"
          ></b-form-radio-group>
        </b-col>
        <b-col v-if="is_add" cols="6" md="2">
          <b-button block @click="onAddEvent" variant="primary"
            >Add {{ $route.name == "movielist" ? "Movie" : "Event" }}</b-button
          ></b-col
        >
      </b-row>
    </section>
    <b-card no-body header-tag="header" footer-tag="footer">
      <b-card-text>
        <b-table
          bordered
          responsive
          :fields="fields"
          :items="items"
          :busy="isBusy"
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(sr)="data">
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}
          </template>

          <template #cell(event_is_active)="data">
            {{ data.item.event_is_active == "Y" ? "Active" : "Disabled" }}
          </template>
          <template #cell(event_booking_active)="data">
            {{ data.item.event_booking_active == "Y" ? "Active" : "Disabled" }}
          </template>
          <template #cell(event_is_private)="data">
            {{ data.item.event_is_private == "Y" ? "Yes" : "No" }}
          </template>

          <template #cell(event_id)="data">
            {{
              WEB_URL +
              "/events/" +
              data.item.event_id +
              "/" +
              data.item.event_name
            }}
          </template>

          <template v-if="is_update" #cell(action)="data">
            <div class="text-nowrap">
              <b-button
                class="mr-1"
                @click="onEditItem(data.item)"
                size="sm"
                variant="outline-primary"
                >Edit</b-button
              >
              <b-button
                @click="onOtherInfo(data.item)"
                size="sm"
                variant="outline-warning"
                >Other Info</b-button
              >
            </div>
          </template>
        </b-table>
      </b-card-text>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { GetEventListService } from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { UserService } from "@/apiServices/storageService";
export default {
  data() {
    return {
      searchValue: null,
      WEB_URL: process.env.VUE_APP_WEB_URL,
      isBusy: false,
      fields: [
        {
          key: "sr",
          label: "SR",
        },
        {
          key: "event_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "event_prefix_code",
          label: "Prefix Code",
          sortable: true,
        },

        // {
        //   key: "cinema_address",
        //   label: "Cinema Address",
        //   sortable: true,
        // },
        {
          key: "country_name",
          label: "Country",
          sortable: true,
        },
        {
          key: "city_name",
          label: "City",
          sortable: true,
        },

        {
          key: "event_is_active",
          label: "Status",
          sortable: true,
        },
        {
          key: "event_booking_active",
          label: "Booking Status",
          sortable: true,
        },

        {
          key: "event_is_private",
          label: "Is Private Event",
          sortable: true,
        },

        {
          key: "curr_code",
          label: "Price Currency",
          sortable: true,
        },

        {
          key: "event_id",
          label: "URL/Link",
          sortable: true,
        },

        {
          key: "action",
          label: "",
        },
      ],
      items: [],
      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      },
      debounce: null,
      selectedEventType: "Y",

      optionsEventTypes: [
        { text: `Active `, value: "Y" },
        { text: `Disabled `, value: "N" },
        { text: `All `, value: "" },
      ],
    };
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name) {
        this.getEventList();
      }
    },
  },
  computed: {
    is_add() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "create");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    is_update() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "update");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    is_read() {
      let permissionData = UserService.getUserPermissions();
      let obj = permissionData.find((o) => o.subject == this.$route.name);
      if (obj) {
        let objP = obj.permission.find((p) => p == "read");
        if (objP) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  beforeMount() {
    this.getEventList();
  },

  methods: {
    async onAddEvent() {
      if (this.$route.name == "movielist") {
        this.$router.push({
          path: "/movieform?type=movie",
        });
      } else if (this.$route.name == "eventlist") {
        this.$router.push({
          path: "/eventform?type=event",
        });
      } else {
        alert("Error Occured");
        return;
      }
    },
    async onSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getEventList();
      }, 1000);
    },
    async onEditItem(item) {
      if (this.$route.name == "movielist") {
        this.$router.push({
          name: "movieform",
          query: { event_id: item.event_id, type: "movie" },
        });
      } else if (this.$route.name == "eventlist") {
        this.$router.push({
          name: "eventform",
          query: { event_id: item.event_id, type: "event" },
        });
      } else {
        alert("Error Occured");
        return;
      }
    },
    async onOtherInfo(item) {
      this.$router.push({
        name: "eventOtherInfo",
        query: { event_id: item.event_id },
      });
    },

    async getEventList() {
      try {
        this.isBusy = true;
        const response = await GetEventListService({
          limit: this.pagination.limit,
          currentPage: this.pagination.currentPage,
          search: this.searchValue,
          selectedEventType: this.selectedEventType,
          isMaster: "Y",
          type: this.$route.name == "movielist" ? "movie" : "event",
        });
        if (response.data.status) {
          this.items = response.data.Records;
          // this.items = response.data.Records.data;
          // if (!isNaN(response.data.Records.pagination.total)) {
          //   this.pagination.totalRows = response.data.Records.pagination.total;
          // }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    onChange() {
      this.$nextTick(() => {
        this.getEventList();
      });
    },
  },
};
</script>
